<template>
	<div
		class="s-card-preview"
		:style="{ height: clientHeight }"
		v-if="fileInfo"
	>
		<a-breadcrumb class="s-a-breadcrumb">
			<a-breadcrumb-item>
				<a href="/thinkTank/communityClassroom"> 社区课堂 </a>
			</a-breadcrumb-item>
			<a-breadcrumb-item>视频详情</a-breadcrumb-item>
		</a-breadcrumb>
		<a-row :gutter="16">
			<a-col :span="18">
				<div class="article-title-content">
					<div class="atc-title">
						{{ fileInfo.title }}
					</div>
					<div class="atc-sub-title">
						视频简介：{{ fileInfo.des }}
					</div>
				</div>
				<video-player
					ref="videoPlayer"
					:style="{
						width: '100%',
						height: clientHeight - 122 - 80 - 60,
					}"
					:options="playerOptions"
					:playsinline="true"
				>
				</video-player>
				<!-- <iframe
					:src="fileUrl"
					width="100%"
					:height="clientHeight - 122 - 80 - 60"
					:style="{
						height: clientHeight - 122 - 80,
						boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
					}"
					frameborder="1"
				>
				</iframe> -->
				<!-- <div class="article-footer-content">
					<a-button
						type="primary"
						@click="downloadsHandle(fileInfo._id)"
						:loading="btnLoading"
						>下载</a-button
					>
				</div> -->
			</a-col>
			<a-col :span="6">
				<a-card style="width: 100%" :bordered="false" :extra="null">
					<template slot="title">
						<img
							src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/ico_rm.svg"
						/>热门
					</template>
					<div class="popular-downloads">
						<div
							class="pd-item"
							v-for="(item, index) of hotList"
							:key="index"
							@click="
								$router.push(
									`/thinkTank/communityClassroom/preview/${item._id}`
								)
							"
						>
							<img :src="item.cover" />

							<span>
								{{ item.title }}
							</span>
						</div>
					</div>
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>
<script>
import {
	getCommunityClassroomById,
	updateCommunityClassroomDownloads,
	getCommunityClassroomHot,
} from '@/api/thinkTank/communityClassroom'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
export default {
	data() {
		return {
			playerOptions: {
				height: document.documentElement.clientHeight - 30,
				width: '100%',
				autoplay: true,
				muted: true,
				language: 'en',
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [],
			},
			fileUrl: '',
			clientHeight: 500,
			fileInfo: null,
			btnLoading: false,
			hotList: [],
		}
	},
	components: {
		videoPlayer,
	},
	watch: {
		$route() {
			this.initData()
		},
	},
	created() {
		this.initData()
	},
	mounted() {
		this.clientHeight = `${document.documentElement.clientHeight}`
		window.onresize = function temp() {
			this.clientHeight = `${document.documentElement.clientHeight}`
		}
	},
	methods: {
		initData() {
			let _id = this.$route.params.fileId

			updateCommunityClassroomDownloads(_id)
				.then((res) => {
				
				})
				.catch((err) => {
					console.log(err)
				})

			getCommunityClassroomById(_id).then((res) => {
				this.fileInfo = res
				this.playerOptions.sources = [
					{
						type: 'video/mp4',
						src: res.filePath,
					},
				]
				this.fileUrl = `https://view.xdocin.com/xdoc?_xdoc=${encodeURIComponent(
					res.filePath
				)}&_toolbar=false&_printable=false&_saveable=false&_pdf=false&_copyable=false`
			})
			getCommunityClassroomHot().then((res) => {
				this.hotList = res.list
			})
		},
		downloadsHandle(fileId) {
			this.btnLoading = true
			updateCommunityClassroomDownloads(fileId)
				.then((res) => {
					this.btnLoading = false
					window.open(this.fileInfo.filePath)
				})
				.catch((err) => {
					console.log(err)
					this.btnLoading = false
				})
		},
	},
}
</script>
<style lang="less" scope="this api replaced by slot-scope in 2.5.0+">
.s-card-preview {
	padding-bottom: 36px;
	.video-js {
		width: 100%;
	}
	.s-a-breadcrumb {
		margin-bottom: 16px;
	}
	.article-title-content {
		min-height: 90px;
		width: 100%;
		padding: 16px 24px;
		background: white;
		.atc-sub-title {
			color: #666666;
		}
		.atc-title {
			text-align: center;
			font-size: 24px;
			font-weight: bold;
			color: #000729;
		}
	}
	.article-footer-content {
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: white;
	}
	.popular-downloads {
		.pd-item {
			cursor: pointer;
			margin-bottom: 12px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 8px;
			text-align: left;
			img {
				display: block;
				width: 100%;
				height: 150px;
				margin-top: 5px;
			}
			&:hover {
				color: #2140d9;
			}
			font-size: 14px;
			color: #333333;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
</style>
